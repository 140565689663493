<template>
  <div class="page-layout__inner">
    <div :class="'page-layout__filter' + (filterSettings.visible ? '' : ' w-auto')">
      <section :class="'section  section--shadow-0  section--p-0' + (filterSettings.visible ? ' h-100' : '')">
        <button @click="filterSettings.visible = true" :class="'section__filter-btn' + (filterSettings.visible ? ' section__filter-btn--hidden' : '')">
          <svg class="section__filter-icon">
            <use xlink:href="#icon__filter"></use>
          </svg>
        </button>
        <div :class="'section__filter' + (filterSettings.visible ? '' : ' section__filter--hidden')">
          <div class="section__header">
            <h2 class="section__title">
              <svg class="section__filter-icon">
                <use xlink:href="#icon__filter"></use>
              </svg>
              {{ getTranslation('filter') }}
            </h2>
            <div class="section__controls">
              <div class="section__controls-group">
                <a @click="filterSettings.visible = false" class="btn btn-link " href="javascript:void(0)">{{ getTranslation('hide') }}</a>
                <a class="btn  btn-link" href="javascript:void(0)" @click="resetFilter">{{ getTranslation('reset') }}</a>
              </div>
            </div>
          </div>
          <div class="section__body">
            <div class="form-group">
              <form method="get" @submit.prevent="fetchItems(1)">
                <label class="form__label form__label--block">{{ getTranslation('search') }}</label>
                <div class="field-advanced">
                  <div class="field-advanced__inner">
                    <div class="field-advanced__field-wrap">
                      <input v-model="filterForm.query" class="field  field--full-width  field--bg-grey  js-borderOnFocus" type="text" :placeholder="getTranslation('search')">
                    </div>

                    <div class="field-advanced__right">
                      <button class="btn btn-primary">
                        <svg class="btn__icon">
                          <use xlink:href="#icon__search"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="form-group">
              <label class="form__label--block form__label">{{ getTranslation('status') }}</label>
              <VSelect
                  :options="[{title:this.getTranslation('callback_new'),id:'new'},{title:this.getTranslation('callback_contacted'),id:'contacted'}]"
                  :reduce="option=>option.id"
                  label="title"
                  v-model="filterForm.status"
                  :placeholder="getTranslation('selectFromList')"
                  @input="fetchItems(1)"
              />
            </div>

            <div class="form-group">
              <label class="form__label  form__label--block">{{ getTranslation('addedDate') }}</label>
              <div class="form__tillfrom-row">
                <date-picker v-model="filterForm.date_from" valueType="format"></date-picker>
                <div class="form__tillfrom-sep"></div>
                <date-picker v-model="filterForm.date_to" valueType="format"></date-picker>
              </div>
            </div>

          </div>

        </div>
      </section>
    </div>
    <div class="page-layout__content">
      <div class="row col-md-12">
        <p class="text-muted mb-3">{{ getTranslation('resultsFoundWithQuantity', pagination.total) }}</p>
        <div class="table-responsive table-3-wrapper">
          <table class="table table-borderless">
            <thead class="table-3__head ">
            <tr class="table-3__row">
              <th class="table-3__cell  table__hcell" style="width: 0.1%">#</th>
              <th class="table-3__cell  table__hcell" style="width: 15%">{{ getTranslation('Full Name') }}</th>
              <th class="table-3__cell  table__hcell" style="width: 15%">{{ getTranslation('email') }}</th>
              <th class="table-3__cell  table__hcell" style="width: 15%">{{ getTranslation('Phone number') }}</th>
              <th class="table-3__cell  table__hcell" style="width: 45%">{{ getTranslation('Message') }}</th>
              <th class="table-3__cell  table__hcell" style="width: 8%">{{ getTranslation('addedDate') }}</th>
              <th class="table-3__cell  table__hcell" style="width: 8%">{{ getTranslation('status') }}</th>
              <th class="table-3__cell  table__hcell" style="width: 0.5%"></th>
            </tr>
            </thead>
            <tbody class="table-3__body">
            <tr v-for="item in items" class="table-3__row table__link-row" v-bind:key="item.id">
              <td class="table-3__cell">{{ item.id }}</td>
              <td class="table-3__cell">{{ item.name }}</td>
              <td class="table-3__cell">{{ item.email }}</td>
              <td class="table-3__cell">{{ item.phone }}</td>
              <td class="table-3__cell">{{ item.message }}</td>
              <td class="table-3__cell">{{ item.created_at }}</td>
              <td class="table-3__cell">
                <span class="badge rounded-pill"
                      :class="{'badge-primary':item.status === 'contacted','badge-success':item.status === 'new' }">
                {{ getTranslation('callback_' + item.status) }}
              </span></td>
              <td v-if="hasPermission('application.requestCallback.contacted')" class="table-3__cell">
                <button v-if="item.status==='new'" @click="contact(item)" class="btn btn-sm btn-primary" :title="getTranslation('callback_contacted')">
                  <CIcon class="action-icon" name="cil-check"/>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestCallbacks",
  data() {
    return {
      filterSettings: {
        visible: false
      },
      items: [],
      pagination: {
        total: 1,
        last_page: 1,
        current_page: 1
      },
      filterForm: {
        status: this.$route.query.status || ''
      },
    }
  },
  created() {
    this.fetchItems();
    this.setHeadings({
      title: this.getTranslation('requestCallbacks'),
      breadcrumbs: [
        {
          to: { name: 'application.requestCallback.getList' },
          text: this.getTranslation('requestCallbacks')
        }
      ],
    });
  },
  watch: {
    'filterForm.date_from'() {
      this.fetchItems(1);
    },
    'filterForm.date_to'() {
      this.fetchItems(1);
    },
    'pagination.current_page'(val) {
      this.fetchItems(val);
    },
  },
  methods: {
    contact(item) {
      Swal.fire({
        title: this.getTranslation('areYouSure'),
        text: this.getTranslation("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.getTranslation('OK')
      }).then((result) => {

        if ( result.isConfirmed ) {
          this.axios.post(this.$urls.application.requestCallbackContact.url.replace(':id', item.id))
              .then(response => {
                this.snotifySuccess('operationSuccess');
                this.fetchItems(this.pagination.current_page);
              }).catch(() => {
          });

        }
      })

    },
    fetchItems(page = null) {

      if ( page )
        this.filterForm.page = 1;

      this.axios.get(this.$urls.application.requestCallbacks.url, { params: this.cleanObject({ ...this.filterForm }) })
          .then(response => {

            this.items = response.data.data.items;
            this.pagination = response.data.data.pagination;

          }).catch(() => {
      });
    },
    resetFilter() {
      this.filterForm = {
        status: this.$route.query.status || ''
      };
      this.fetchItems(1);
    }
  }
}
</script>

<style scoped>

</style>